
import Vue from 'vue';
import SolicitudService from '@/services/SolicitudService';
import rules from "@/mixins/rules";
import {mapGetters} from "vuex";

export default Vue.extend({
    name: 'IsabisDashboard',
    mixins: [rules],
    components: {},
    data: () => ({
        headers: [
            { text: '', align: 'center', sortable: false, value: 'exclusive' },
            { text: 'ESTATUS DE LA SOLICITUD (ISABI)', align: 'start', sortable: false, value: 'estatus' /* value: role === 'notario' ? 'estatus.notario' : 'estatus.municipio' */ },
            { text: 'TIPO DE OPERACIÓN', align: 'start', sortable: false, value: 'tipo_operacion.descripcion' },
            { text: 'NÚM ESCRITURA', align: 'start', sortable: false, value: 'escritura.numero' },
            { text: 'FECHA ESCRITURA', align: 'start', sortable: false, value: 'escritura.fecha_escritura' },
            { text: 'FECHA REGISTRO', align: 'start', sortable: false, value: 'fecha_registro' },
            { text: 'CLAVE CATASTRAL', align: 'start', sortable: false, value: 'inmueble.clave_catastral' },
            { text: 'FECHA EXPIRACIÓN', align: 'start', sortable: false, value: 'fecha_expiracion' },
            { text: 'ORDEN DE PAGO', align: 'start', sortable: false, value: 'solicitud_historial' },
        ],
        list:[],
        expanded: [],
    }),
    computed: {
        ...mapGetters({
            usuario: 'usuario', modules: 'modules' , email: 'email' , perfil: 'perfil', id: 'id', role: 'role'
        })
    },
    created() {
        // @ts-ignore
        this.expanded = this.list.map(item => item.id);
    },
    mounted() {
        this.initialize();
    },
    methods: {
        
        // @ts-ignore
        formatClaveCatastral(clave) {
            if (!clave) return ''
            // Asegúrate de que la clave sea una cadena
            clave = clave.toString()
            // Formatear la clave catastral según el patrón ####-###-####-######
            return `${clave.slice(0, 4)}-${clave.slice(4, 7)}-${clave.slice(7, 11)}-${clave.slice(11)}`
        },
        // @ts-ignore
        formatPrecio(precio) {
            return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(precio)
        },
        //@ts-ignore
        getColorByStatusNotario(status: string): string {
            if( this.role === 'notario' || this.role === 'auxiliar' ) {
                switch (status) {
                case 'PENDIENTE DE FIRMA':
                    return '#F9A825';
                case 'FIRMADO, LISTO PARA ENVÍO':
                    return '#42ade5';
                case 'EN VALIDACIÓN POR EL ÁREA':
                    return '#439343';
                case 'RECHAZADO POR EL ÁREA':
                    return '#E53935';
                case 'VALIDADO Y PENDIENTE DE FIRMA POR EL ÁREA':
                    return '#4DB6AC';
                case 'FIRMADO POR EL ÁREA':
                    return '#396926';
                case 'ORDEN DE PAGO GENERADO':
                    return ' #7133ff';
                default:
                    return 'grey';
                }
            }
            if( this.role === 'operativoMunicipio' ) {
                switch (status) {
                case 'PENDIENTE DE VALIDAR':
                    return '#F9A825';
                case 'RECHAZADO POR EL ÁREA':
                    return '#E53935';
                case 'PEND. FIRMA DIRECTORA':
                    return '#2e93c4';
                case 'FIRMADO POR LA DIRECTORA':
                    return '#43A047';
                case 'FINALIZADO':
                    return '#33691E';
                case 'ORDEN DE PAGO GENERADO':
                    return ' #7133ff';
                default:
                    return 'grey';
                }
            }
        },
        async initialize() {
            let dato = {
                id:this.id,
                role:this.role
            }
            let date = new Date()
            let day = `${(date.getDate())}`.padStart(2,'0');
            let month = `${(date.getMonth()+1)}`.padStart(2,'0');
            let year = date.getFullYear();
            let fechaCompleta = `${year}-${month}-${day}`;

            await SolicitudService.isabisDashboard(dato).then(
                (response) => {
                    if(response.data.success){
                        let resultado = response.data.data;
                        //@ts-ignore
                        this.list = resultado.sort((a, b) => 
                          Date.parse(a.solicitud_historial[a.solicitud_historial.length - 1].solicitud_edo_cta[a.solicitud_historial[a.solicitud_historial.length - 1].solicitud_edo_cta.length - 1].fecha_expiracion) - Date.parse(b.solicitud_historial[b.solicitud_historial.length - 1].solicitud_edo_cta[b.solicitud_historial[b.solicitud_historial.length - 1].solicitud_edo_cta.length - 1].fecha_expiracion)
                        );
                        console.log(this.list);
                    }
                }
            );
        },
    }
});
