
import Vue from 'vue';
import Sidebar from './Sidebar.vue';
import EventBus from '@/plugins/event-bus';

export default Vue.extend({
    name: 'Full',
      data: () => ({
        bus: EventBus,
        dialog: false,
        overlay: false,
        footer: process.env.VUE_APP_FOOTER_INFO
      }),
      created(){
        EventBus.$on('before-request', () => {
            this.overlay = true;
        });
        EventBus.$on('after-response', () => {
            this.overlay = false;
        });
          EventBus.$on('response-error', () => {
              this.overlay = false;
          });
        EventBus.$on('request-error', () => {
          this.overlay = false;
        });
      },
      components: {
        Sidebar
      }
})
