
import Vue from 'vue'
import RULES from "@/mixins/rules";
import {VForm} from "@/types/formvalidate";
import LoginService from "@/services/LoginService";
import {CatalogoService} from "@/services/CatalogoService"
export default Vue.extend({
  name: 'RecoveryPassword',
  mixins: [RULES],
  directives: {},
  components: {},
  data: () => ({
    serviceEntidades: new CatalogoService('entidad-public'),
    municipios: [],
    vista: 0,
    showPassword: false,
    showPass1: false,
    showPass2: false,
    entidades: [],
    form: {
      nombre: '',
      primer_apellido: '',
      segundo_apellido: '',
      rfc: '',
      curp: '',
      telefono: '',
      email: '',
      numero_notaria: '',
      nombre_notaria: '',
      direccion_notaria: '',
      poblacion_notaria: '',
      entidad_notaria: ''
    },
  }),
  created() {
  },
  mounted() {
    //
  },
  computed: {
    forms(): VForm {
      return this.$refs.signin as VForm
    },
  },
  watch: {},
  methods: {
    async ReacoveryPassword() {
      if (this.forms.validate()) {
        let {data} = await LoginService.RecoveryPassword(this.form);
        if (data.success) {
          this.$toast.success(data.message);
          await this.$router.push({name: 'Full'});
        } else
          this.$toast.error(data.message);
      }
    },
  },
})
