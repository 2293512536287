import Vue from 'vue'
import {VForm} from "@/types/formvalidate";

export default Vue.extend({
    props: {
        show: {
            type: Boolean,
            default: false
        },
        model: {
            type: Object,
            default: null,
            required: true,
        },
        service: {
            type: Object,
            default: null,
            required: true,
        },
    },
    directives: {},
    data: () => ({
        dialog: false,
        modal: false,
        formdata: {
            id: ''
        },
        formdefault: {
            id: ''
        },
    }),
    mounted() {
        this.formdata = JSON.parse(JSON.stringify(this.model));
        this.formdefault = JSON.parse(JSON.stringify(this.model));
    },
    computed: {
        form(): VForm {
            return this.$refs.form as VForm
        }
    },
    watch: {
        model: {
            immediate: true,
            deep: true,
            handler(val) {
                if (val != null) {
                    this.formdata = Object.assign({}, val);
                } else
                    this.formdata = Object.assign({}, this.formdefault)
            }
        },
        show: {
            immediate: true,
            handler(val) {
                this.dialog = val;
            }
        }
    },
    methods: {
        close(reload: boolean = false) {
            this.formdata = Object.assign({}, this.formdefault);
            this.form.resetValidation();
            this.$emit('close-dialog', reload);
        },
        async save() {
            let data = null;
            if (this.form.validate()) {
                if(this.formdata.id!=undefined)
                    data = await this.service.update(this.formdata.id, this.formdata);
                else
                    data = await this.service.create(this.formdata);
                this.formdata = Object.assign({}, this.formdefault);
                this.close(true);
                if (data.data.success) {
                    this.dialog = !this.dialog;
                    this.$toast.success(data.data.message);
                  }else{
                    this.$toast.error(data.data.message);
                  }
            }
        }
    }
});
