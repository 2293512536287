import {DefaultService} from "@/services/DefaultService";
import API from "@/plugins/axios";

class NotariaService extends DefaultService {

    constructor() {
        super('notarias');
    }

    getById(notaria: number | string): Promise<any> {
        return API.get(`notarias/${notaria}`);
    }

    getAllRecords(filters: any): Promise<any>{
        let query = new URLSearchParams(filters).toString();
        return API.get('notarias/getAll' + '?' + query);
    }
    getAllByRol(role: any, filters: any): Promise<any>{
        let query = new URLSearchParams(filters).toString();
        return API.get('notarias/'+role+'/getByRol' + '?' + query);
    }

}

export default new NotariaService();