interface _INPC {
  id: number;
  anio_fiscal: number;
  mes: string;
  indice: number;
  publicacion: Date | null;
  
}

class INPC implements _INPC {
  id = 0;
  anio_fiscal = 0;
  mes = '';
  indice = 0;
  publicacion = null;
}

export { INPC, _INPC };
