import {DefaultService} from "@/services/DefaultService";
import API from "@/plugins/axios";

class CatalogoService extends DefaultService{

    constructor(url: string) {
        super(url);
    }

    // @ts-ignore
    /*
    create(url:any, data: any): Promise<any> {
        // console.log('entrooo')
        return API.post(url, data).then((res) => res.data);
    } */

}

export {CatalogoService};
