interface _TasaImpuestos {
  id: number;
  valor: number;
  anio: number;
  activo: boolean;
}

class TasaImpuestos implements _TasaImpuestos {
  id: number = 0;
  valor: number = 0;
  anio: number = 0;
  activo: boolean = false;
}

export { TasaImpuestos, _TasaImpuestos };
