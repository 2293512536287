
import Catalogo from '@/mixins/catalogo'
import RULES from '@/mixins/rules'

export default Catalogo.extend({
  name: 'FormTipoPersona',
  mixins: [RULES],
  data: () => ({
    menu1: false
  })
})
