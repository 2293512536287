
import Catalogo from '@/mixins/catalogo'
import RULES from '@/mixins/rules'
import {mapGetters} from "vuex";
import {CatalogoService} from "@/services/CatalogoService";

export default Catalogo.extend({
  name: 'FormNotaria',
  mixins: [RULES],
  data: () => ({
    serviceMunicipios: new CatalogoService('catalogos/municipio'),
    serviceEntidad: new CatalogoService('catalogos/entidad'),
    catalogos: {
      municipios: [],
      entidad: []
    },
    valDato: false,
  }),
  mounted() {
    this.getAllData()
  },
  methods: {
    async getAllData() {
      try {
        const [municipiosRes] = await Promise.all([
          this.serviceMunicipios.getAll()
        ]);

        const [entidadRes] = await Promise.all([
          this.serviceEntidad.getAll()
        ]);

        this.catalogos.municipios = municipiosRes.data;
        this.catalogos.entidad = entidadRes.data;

      } catch (error) {
        console.error("Error al obtener datos:", error);
      }
    },
    validar(){
      //@ts-ignore
      if(this.formdata.entidad_id != 23){
        //@ts-ignore
        this.formdata.municipio_id = "";
        this.valDato = true;
      }else{
        this.valDato = false;
      }
    }
  }
})
