
import Vue from 'vue'
import {_Usuario,Usuario} from '@/models/Usuario'
import RULES from '@/mixins/rules'
import {VForm} from '@/types/formvalidate'
import UserAuxiliaryService from "@/services/UserAuxiliaryService";
import {Vex} from "@/plugins/vex";
export default Vue.extend({
  name: 'FormAdmin',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    usuario: {
      type: Object as () => _Usuario,
      default: null,
      required: false,
    }
  },
  mixins: [RULES],
  directives: {},
  data: () => ({
    catalogos: {
      tipos_auxiliar: [
        {id:'auxiliar', name: 'auxiliar'},
        {id:'suplente', name: 'suplente'},
      ]
    },
    dialog: false,
    modal: false,
    showNip: false,
    showNip2: false,
    formUser: new Usuario(),
    formDefault: new Usuario(),
  }),
  mounted() {
  },
  computed: {
    form(): VForm {
      return this.$refs.form as VForm
    },
  },
  watch: {
    usuario: {
      immediate: true,
      deep: true,
      handler(val){
        if(val!=null){
          this.formUser = Object.assign({}, val);
          // @ts-ignore
        }else
          this.formUser = Object.assign({}, this.formDefault)
      }
    },
    show: {
      immediate: true,
      handler(val) {
        this.dialog = val;
      }
    }
  },
  methods: {
    validatePass(value:string|undefined) {
      const pswrd = (this.formUser.password === undefined)?'':this.formUser.password;
      if(value === undefined ||pswrd.length===0){
        return true;
      }
      if(value.length < 8){
        return 'Mínimo 8 caracteres';
      }
      return true;
    },
    reValidatePass(value:string|undefined) {
      const pswrd = (this.formUser.password === undefined)?'':this.formUser.password;
      const c_pswrd =(this.formUser.c_password === undefined)?'':this.formUser.c_password;
      if((pswrd.length>0)&&(value === undefined || c_pswrd.length===0)){
        return 'El campo es requerido';
      }
      if(value === undefined || c_pswrd.length===0){
        return true;
      }
      if(value.length<8){
        return 'Mínimo 8 caracteres';
      }
      if(pswrd !== value){
        return 'Debe agregar la contraseña y confirmarla.';
      }
    },
    close(reload: boolean = false) {
      this.formUser = Object.assign({}, this.formDefault);
      this.form.resetValidation();
      this.$emit('close-dialog', reload);
    },
    async save() {
      let data = null;
      if (this.form.validate()) {
        const hasId = this.formUser.hasOwnProperty('id');
        if(hasId)
          data = await UserAuxiliaryService.update(this.formUser.id, this.formUser);
        else
          data = await UserAuxiliaryService.create(this.formUser);
        this.formUser = Object.assign({}, this.formDefault);
        this.close(true);
        if (data.data.success) {
          this.dialog = !this.dialog;
          this.$toast.success(data.data.message);
        }else{
          this.$toast.error(data.data.message);
        }
      }
    }
  }
})
