import store from "@/store"

export default async (to, from, next) => {
    if(!store.state.perfil_completo){
        //Consultar endpoint
        next({ name: 'notario' });
        return false;
    }
}

