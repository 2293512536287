import Json from '@/types/json';

interface _Enajenante {
    id: number | null;
    tipo_persona_id: number | null;
    nombre: string;
    domicilio: string;
    telefono: string;
    rfc: string;
    poblacion: string;
    entidad_id: number | null;
}

class Enajenante implements _Enajenante{
    id: number = 0;
    tipo_persona_id = null;
    nombre: string = '';
    domicilio: string = '';
    telefono: string = '';
    rfc: string = '';
    poblacion: string = '';
    entidad_id = null;
    setData(data: Json){
        // @ts-ignore
        this.id = null;
        // @ts-ignore
        this.tipo_persona_id = null;
        // @ts-ignore
        this.nombre = data.nombre;
        // @ts-ignore
        this.domicilio = data.domicilio;
        // @ts-ignore
        this.telefono = null;
        // @ts-ignore
        this.rfc = null;
        // @ts-ignore
        this.poblacion = null;
        // @ts-ignore
        this.entidad_id = null;
    }
}

export {Enajenante,_Enajenante}