import Json from '@/types/json'

interface _Inmueble {
    id: number | null;
    folio: string;
    clave_catastral: string;
    ubicacion: string;
    numero: string;
    entidad_id: number | null;
    municipio_id: number | null;
    poblacion: string;
    fecha_avaluo: Date | null;
    precio_pactado: string;
    avaluo_bancario: string;
    avaluo_catastral: string | null;
    avaluo_catastral_original: string | null;
    avaluo_fiscal: string;
    clave_catastral_subdivision: string;
    avaluo_catastral_subdivision: string;
    aplica_porcentaje_avaluo: boolean | null;
    porcentaje_avaluo: string | null;
}

class Inmueble implements _Inmueble{
    id: number = 0;
    folio: string = '';
    clave_catastral: string = '';
    ubicacion: string = '';
    numero: string = '';
    entidad_id = 0;
    municipio_id = 0;
    poblacion: string = '';
    fecha_avaluo = null;
    precio_pactado: string = '';
    avaluo_bancario: string = '';
    avaluo_catastral: string = '';
    avaluo_catastral_original: string = '';
    avaluo_fiscal: string = '';
    clave_catastral_subdivision: string = '';
    avaluo_catastral_subdivision: string = '';
    aplica_porcentaje_avaluo = false;
    porcentaje_avaluo = null
    setData(data: Json){
    }
}

export {Inmueble,_Inmueble}