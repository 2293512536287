
import Vue from 'vue';
import NotariaService from "@/services/NotariaService";
import rules from '@/mixins/rules';
import moment from 'moment';

export default Vue.extend({
  name: 'TaxPayer',
  mixins: [rules],
  data: () => ({
    headers: [
      { text: 'Número', align: 'start', sortable: false, value: 'id' },
      { text: 'Nombre', align: 'start', sortable: false, value: 'descripcion' },
      { text: 'Municipio', align: 'start', sortable: false, value: 'municipio_id' },
      { text: 'Colonia', align: 'start', sortable: false, value: 'colonia_id' },
      { text: 'Calle', align: 'start', sortable: false, value: 'calle_id' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    items: [
      { id: 4, name: 'Aprobados' },
      { id: 5, name: 'Rechazados' },
      { id: 10, name: 'Pendientes' },
    ],
    notarias: [] as any[],
    roles: null,
    filters: {
      rfc: '',
      nombre_completo: '',
      fecha_inicio: '',
    },
    menu: false,
  }),
  computed: {
    noDataText() {}
  },
  watch: {},
  mounted() {
    this.getData()
  },
  methods: {
    formatDate(value: any) {
      return moment(value).format("Y/MM/DD")
    },
    recoveryFilters(){
      const savedFilters =  localStorage.getItem('filters-taxPayer');
      if (savedFilters !== null) {
        if (savedFilters) {
          const parsedFilters = JSON.parse(savedFilters);
          this.filters = parsedFilters;
        }
      }
    },
    saveFilters() {
      localStorage.setItem('filters-taxPayer', JSON.stringify(this.filters));
    },
    async getData() {
      this.roles = this.$store.getters.role;
      let { data } = await NotariaService.getAllRecords(this.filters);
      if (data){
        this.notarias = data;
      }
    },
  },
  filters: {
    formatoFecha(value: any) {
      const date = new Date(value);
      return date.toLocaleDateString('es-ES', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    }
  }
})

