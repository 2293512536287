import {DefaultService} from "@/services/DefaultService";
import API from '@/plugins/axios'

class SatService extends DefaultService{

    constructor() {
        super('notario');
    }

   create(data: any): Promise<any> {
        return API.post(`/sat/seal`, data,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => res.data);
    }
}

// export {SatService};
// @ts-ignore
export default new SatService();
