import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'
import VueI18n from 'vue-i18n'
import es from 'vuetify/src/locale/es'
Vue.use(Vuetify);
Vue.use(VueI18n)

export default new Vuetify({
  lang: {
    locales: {es},
    current: 'es',
  },
  theme: {
    themes: {
      light: {
        primary: '#e91e63',
        secondary: '#f44336',
        accent: '#9c27b0',
        error: '#ff5722',
        warning: '#ff9800',
        info: '#03a9f4',
        success: '#3f51b5'
        },
    },
  },
  icons: {
    iconfont: 'mdi',
  },
});
