import {DefaultService} from "@/services/DefaultService";
import API from "@/plugins/axios";

class SolicitudService extends DefaultService {

  constructor() {
    super('solicitudes');
  }

  getById(solicitud: number | string): Promise<any> {
    return API.get(`solicitudes/${solicitud}`);
  }

  changeStatus(solicitud: number, data: any): Promise<any> {
    return API.post(`solicitudes/${solicitud}/changestatus`, data);
  }

  getAllByRol(role: any, filters: any): Promise<any>{
    let query = new URLSearchParams(filters).toString();
    return API.get('solicitudes/'+role+'/getByRol' + '?' + query);
  }

  descargarFormato(url: any, data: any, type: any, name: string){
    return API.request({
      url: url,
      data: data,
      method: type,
      responseType: 'blob'
    });
  }

  ExportarIsabi(data: any): Promise<any>{
    // return API.get('solicitudes/export',{ responseType: 'blob' }).then((res) => res);
    return API.post('solicitudes/exports', data, {responseType: 'blob'});
  }

  deleteFiles(archivo: number,id: any = null ): Promise<any>{
    return API.post(`solicitudes/${archivo}/${id}/deleteFile`);
  }

  ValidarSoli(solicitud: any): Promise<any>{
    return API.post(`/ValidarSoli`,solicitud);
  }
  ValidarPagoOPB(folio: any): Promise<any>{
    return API.post(`/ValidaPagoEfectuado`,folio);
  }
  reflejarPagadosPendientes(): Promise<any>{
    return API.post(`/reflejarPagosPendientes`);
  }

  RechazarSoli(data: any): Promise<any>{
    return API.post(`/RechazarSoli`,data);
  }

  updateAprobar(data: any): Promise<any>{
    return API.post(`/solicitudes/aprobar`,data);
  }

  obtenerEstadisticas(solicitud: any): Promise<any>{
    return API.post(`/obtenerEstadisticas`,solicitud);
  }

  aplicarDescuento(data: any): Promise<any>{
    return API.post(`/aplicarDescuento`,data);
  }
  
  getAllPagos(): Promise<any>{
    return API.get(`/getAllPagos`);
  }

  isabisDashboard(datos:any): Promise<any>{
    return API.post(`/isabisDashboard`,datos);
  }

  SolicitarEdoCta(datos:any): Promise<any>{
    return API.post(`/solicitarEdoCta`,datos);
  }

}

export default new SolicitudService();
