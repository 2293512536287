import Json from '@/types/json'
interface _FileDocument {
    code: string,
    file: string,
    binary: string,
    data: any
}

class FileDocument implements _FileDocument {
    code: string
    file: string
    binary: string
    data: any

    constructor() {
        this.code = '';
        this.file = '';
        this.binary = '';
        this.data = null;
    }
}

interface _Usuario {
    id: number | null
    grado_academico: string
    nombre: string
    primer_apellido: string
    segundo_apellido: string
    rfc: string
    curp: string
    telefono: string
    notaria_id: number | null
    user_owner_id: number | null
    password: string
    c_password: string
    auxiliary_type: string | null
    perfil_completo: number
    status: number
    email: string
    numero_notaria: number
    nombre_notaria: string
    direccion_notaria: string
    localidad_notaria: string
    municipio_notaria: string
    entidad_notaria: string
    poblacion: string
    documento_binary: any
    motivo_rechazo: string
    entidad_id: number | null
    municipio_id: number | null
    patente: FileDocument
}

class Usuario implements _Usuario{
    id = null
    grado_academico = ''
    nombre = ''
    primer_apellido = ''
    segundo_apellido = ''
    rfc = ""
    curp = ""
    telefono = ""
    notaria_id = null
    user_owner_id = null
    password = ""
    c_password = ""
    auxiliary_type = ""
    perfil_completo = 0
    status = 0
    email = ""
    numero_notaria = 0
    nombre_notaria = ""
    direccion_notaria = ""
    localidad_notaria = ""
    municipio_notaria = ""
    entidad_notaria = ""
    documento_binary = ''
    poblacion = ""
    motivo_rechazo = ""
    entidad_id = 0
    municipio_id = null
    patente = new FileDocument()
    setData(data: Json){

    }
}

export {_Usuario,Usuario}