
import Vue from "vue";
import LoginService from '@/services/LoginService';
import {mapGetters} from "vuex";
import RULES from '@/mixins/rules';

export default Vue.extend({
    name: 'Notification',
    mixins: [RULES],
    directives: {},
    props: {
        contribuyente: Object
    },
    data: () => ({
        dialog: false,
        notificaciones: [],
        totalNotificaciones: null,
        formNotificacion: {
            message: '',
            areaId: 0,
            establecimientoId: 0,
            rfc: '',
            nombre: '',
        },
        dialogNotificacion: false,
        contribuyenteAreaId: 0,
        areas: [
            { nombre: 'ÁREA DE PREDIAL H. AYUNTAMIENTO DE OTHÓN P. BLANCO', id: 20 },
        ],
        establecimientos: [],
    }),
    created() {
    },
    mounted() {
        this.getNotifications();
    },
    computed: {
        ...mapGetters({
            id: 'id',
        })
    },
    watch: {
        dialog: function(value) {
            if(value){
                this.getNotifications();
            }
        },
    },
    methods: {
        getAreaId(areaId: number) {
            const area = this.areas.find(area => area.id === areaId);
            return area ? area.id : 0;
        },
        cerrarNotify() {
            this.dialog = false;
        },
        leerNotify(notify: any, index: number) {
            this.notificaciones.splice(index, 1);
            //@ts-ignore
            --this.totalNotificaciones;
            LoginService.readNotification(this.id, notify.id).then(response => {
            });
        },
        getNotifications() {
            //this.getNotario();
            LoginService.notificaciones(this.id).then((response) => {
                this.notificaciones = response.data;
            }) 
        },
        sendNotification() {
            // 20 licencias, 6 usoSuelo, 7 anuencia, 20 residuosSolidos
            const areaId = this.getAreaId(this.formNotificacion.areaId);
            const role = this.$store.getters.role;
            
            this.formNotificacion.rfc = this.$store.getters.rfc;
            this.formNotificacion.nombre = this.$store.getters.usuario;
            console.log(this.formNotificacion.nombre);
            if(this.formNotificacion.nombre == ''){
                this.formNotificacion.nombre = this.$store.getters.email + ' - ' + this.$store.getters.rfc;
            }
            console.log(this.formNotificacion.nombre);
            if (areaId !== 0) {
                LoginService.sendNotification(role, this.formNotificacion).then((response) => {
                    if (response.data.success) {
                        this.dialogNotificacion = false;
                        this.formNotificacion = {
                            message: '',
                            areaId: 0,
                            establecimientoId: 0,
                            rfc: '',
                            nombre: '',
                        };
                        this.$toast.success(response.data.message);
                    } else {
                        this.$toast.error(response.data.message);
                    }
                })
            } else {
                this.$toast.error('Por favor, llene todos los campos');
            }
        },
        readAllNotify() {
            this.notificaciones = [];
            //@ts-ignore
            this.totalNotificaciones = 0;
            LoginService.readAllNotification(this.id).then(response => {
                this.$toast.success("Notificaciones eliminadas");
            });
        },

    }
});
