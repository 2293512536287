
import Vue from 'vue'
import UserService from "@/services/UserService";
import {mapGetters} from "vuex";
import {Usuario} from "@/models/Usuario";
import RULES from '@/mixins/rules'
import UnMask from '@/common/unmask';
import rules from '@/mixins/rules';
import {VForm} from "@/types/formvalidate";

export default Vue.extend({
  name: 'Perfil',
  mixins: [RULES],
  data () {
    return {
      loading: false,
      editable: false,
      formUser: new Usuario(),
      formDefault: new Usuario(),      
      firma_password: "",
      firma_confirm_password: "",
      showNewPassword: false,
    }
  },
  computed: {
    ...mapGetters({
      usuario: 'usuario', id: 'id' , role: 'role'
    }),
    form(): VForm {
                return this.$refs.perfil as VForm
            },
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(){
      let {data} = await UserService.get(this.id);
      this.formDefault = data;
      this.formUser = Object.assign({}, this.formDefault);
    },
    editar(){
      this.editable = true;
    },
    cancelar(){
      this.formUser = Object.assign({}, this.formDefault);
      this.editable = false;
    },
    async update(){
      let formdata = Object.assign({}, this.formUser);
      let {data} = await UserService.update(this.id, formdata);
      if(data.success) {
        this.$toast.success('Perfil actualizado exitosamente');
        await this.$store.dispatch('updateUser', this.formUser.nombre)
        this.formDefault = Object.assign({}, this.formUser);
      }
      this.editable = false;
    },
    async firmaPassword(){
      if(this.form.validate()){
        if(this.firma_password == this.firma_confirm_password){
          let formData = { firma_password: this.firma_password , updateFirma: true};
          let {data} = await UserService.update(this.id, formData);
          if(data.success) {
            this.$toast.success('Perfil actualizado exitosamente');
            setTimeout(() => {
                location.reload();
              }, 2000)
          }else{
            this.$toast.error(data.message);
          }
        }else{
          this.$toast.error('Las contraseñas no coinciden');
        }
        this.getData();
      }else{
        this.$toast.error('Rellene los datos por favor');
      }
    }
  }
})
