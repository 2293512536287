interface _TasaRecargo {
    id: number;
    anio_fiscal: number;
    mes: string;
    tasa_moratoria: number;
    tasa_prorroga: number;
    publicacion: Date | null;
}
  
class TasaRecargo implements _TasaRecargo {
    id = 0;
    anio_fiscal = 0;
    mes = '';
    tasa_moratoria = 0;
    tasa_prorroga = 0;
    publicacion = null;
}
  
  export { TasaRecargo, _TasaRecargo };
  