import { DefaultService } from "@/services/DefaultService";
import API from "@/plugins/axios";

class ViewPDFService extends DefaultService {
    constructor() {
        super('reportes');
    }
    viewPDF(url: any, data: any, type: any, name: string): Promise<any>{
        return API.request({
            url: url,
            data: data,
            method: type,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: false,
            responseType: 'blob'
        });
    }
}

export default new ViewPDFService;