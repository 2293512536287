
import Vue from 'vue'
import UserService from '@/services/UserService'
import {_Usuario, Usuario} from '@/models/Usuario'
import FormCreate from '@/components/Usuarios/FormCreate.vue'
import {Vex} from '@/plugins/vex';
import {mapGetters} from "vuex";

export default Vue.extend({
  name: 'Usuarios',
  mixins: [],
  components: {
    FormCreate
  },
  data: () => ({
    dialog: false,
    dialogT: false,
    usuarios: [] as _Usuario[],
    usuario: new Usuario(),
    search: '',
    headers: [
      { text: 'Id', value: 'id', align: 'left', width: 100  },
      { text: 'RFC', value: 'rfc', align: 'left', width: 150  },
      { text: 'Nombre', value: 'nombre_completo', align: 'left'  },
      { text: 'Rol', value: 'tipo_usuario', align: 'left'  },
      { text: 'Usuario', value: 'username', align: 'left'  },
      { text: 'Email', value: 'email', align: 'center'  },
      { text: 'Estatus', value: 'estatus', align: 'center', width: 120  },
      { text: 'Tableta', value: 'tableta_count', align: 'center', width: 120  },
      { text: '', value: 'action', width: 240, sortable: false },
    ]
  }),
  mounted() {
    this.cargar();
  },
  computed: {
    ...mapGetters({
      role: 'role'
    })
  },
  watch: {},
  methods: {
    async cargar(){
      let {data} = await UserService.getAll();
      if(data.success)
        this.usuarios = data.data;
    },
    openNew(){
      this.dialog = true;
    },
    editar(item: any){
      this.usuario = Object.assign({}, item);
      this.dialog = true;
    },
    asignar(item: any){
      this.usuario = Object.assign({}, item);
      this.dialogT = true;
    },
    async eliminar(item: { id: any, rfc: string }){
      // @ts-ignore
      Vex.dialog.confirm({
        message: `¿Realmente deseas eliminar el usuario con RFC: ${item.rfc}?`,
        callback: async (value: any)  => {
          if (value) {
            let {data} = await UserService.delete(item.id);
            if(data.success){
              this.$toast.warning(`Usuario eliminado exitosamente`);
              await this.cargar();
            }

          }
        }
      })
    },
    activar(item: { id: any; estatus: number, rfc: string }) {
      // @ts-ignore
      Vex.dialog.confirm({
        message: `¿Desea habilitar el acceso del usuario con RFC: ${item.rfc}?`,
        callback: async (value: any)  => {
          if (value) {
            UserService.activar(item.id).then(res => {
              if (res.data.success){
                this.$toast.success(`Usuario habilitado exitosamente`);
                this.cargar();
              }
            });
          }
        }
      })
    },
    desactivar(item: { id: any; estatus: number, rfc: string }) {
      // @ts-ignore
      Vex.dialog.confirm({
        message: `¿Desea deshabilitar el acceso del usuario con RFC: ${item.rfc}?`,
        callback: async (value: any)  => {
          if (value) {
            UserService.desactivar(item.id).then(res => {
              if (res.data.success){
                this.$toast.warning(`Usuario deshabilitado exitosamente`);
                this.cargar();
              }
            });
          }
        }
      })
    },
    closeDialog(reload: boolean){
      this.dialog = false;
      this.dialogT = false;
      this.usuario = new Usuario();
      if(reload)
        this.cargar();
    }
  }
})
