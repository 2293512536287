
import Vue from 'vue'
import RULES from "@/mixins/rules"
import { VForm } from "@/types/formvalidate";
import SolicitudService from "@/services/SolicitudService";

export interface Documento{
    id: number,
    title: string,
    url: string,
    mime: string,
    binary: any
}

export default Vue.extend({
    name: "CustomFileIsabiInput",
    components: {
    },
    mixins: [RULES],
    props: {
        code: String,
        file: Object || null,
        Notario: {
          type: Object,
          default: () => ({}),
          required: false,
        },
        update: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        accept: {
            type: String,
            default: '.pdf'
        },
        icon: {
            type: String,
            default: 'file.jpg'
        },
        loading: {
            type: Boolean,
            default: false,
        },
        customProgress: {
            type: Boolean,
            default: false
        },
        progress: {
            type: Number,
            default: 0,
        },
        multipart: {
            type: Boolean,
            default: true
        },
        requiredArchivo: {
            type: Boolean,
            default: true
        },
        renovacion: {
            type: Number,
            default: 0,
        }
    },
    data() {
        return {
            documento: Document || null,
            binary: '',
            dialog: false,
            show: false,
            fileDeleted: false,
            dialogDelete: false,
            tipoArchivos: [
                {
                    code: 'Escritura',
                    description: 'Escritura pública'
                },
                {
                    code: 'Cedula',
                    description: 'Cédula catastral'
                },
                {
                    code: 'Avaluo',
                    description: 'Avalúo comercial'
                },
                {
                    code: 'Pago',
                    description: 'Recibo de pago impuesto predial o constancia'
                },
                {
                    code: 'Certificado',
                    description: 'Cooperación por obras'
                },
                {
                    code: 'Constancia',
                    description: 'Constancia no adeudo'
                },
                {
                    code: 'Zofemat',
                    description: 'Zona Federal Marítimo Terrestre (ZOFEMAT)'
                },
                {
                    code: 'Descuento',
                    description: 'Documento oficial'
                }
            ],
            filesNotRequired: [
                "testigo1",
                "Avaluo",
                "Zofemat",
                "Certificado",
                "Constancia"
            ],
            typesImageAllowed: [
                "image/jpeg",
                "image/png",
                "image/jpg"
            ],
            labelType:[
                "frente",
                "interior",
                "wc",
                "lateralIzquierdo",
                "lateralDerecho",
            ]
        }
    },
    computed: {
        form(): VForm {
            return this.$refs.form as VForm;
        },
        showInput(): boolean {
            if(!this.update){
                return true;
            }
            else if(this.file == null || this.show){
                return true
            }
            else{
                return false
            }
        },
        label(): string {
            if(this.code != ""){
                //@ts-ignore
                const tipoArchivo = this.tipoArchivos.find((item: any) => {
                    //@ts-ignore
                    return item.code == this.code
                });
                return tipoArchivo?.description ?? '';
            }else
            return '';
            
        },
        requiredFile(): boolean {
            if (this.filesNotRequired.includes(this.code)) {
                return false
            } else {
                return true
            }
        }
    },
    watch: {
        renovacion:{
            immediate: true,
            deep: true,
            handler(d){
                if (this.renovacion == 1) {
                    this.filesNotRequired.push('frente');
                }
            }
        },
    },
    mounted() {
        if (this.renovacion == 1) {
            this.filesNotRequired.push('frente');
        }
    },
    methods: {
        getDataFile(file: any): any {
            return new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.addEventListener("load", () => {
                var file = fileReader.result;
                resolve(file);
                });
                fileReader.readAsDataURL(file);
            });
        },
        async setDocument(e: any) {
          if(e != null){
            if(!this.multipart) {
              this.binary = await this.getDataFile(e);
            } else {
              this.binary = e;
            }

          } else {
            this.binary = '';
          }

          this.$emit('input', this.binary);

        },
        reset() {
          // Reiniciar el valor del input a una cadena vacía
          //@ts-ignore
          this.file = '';
          // Emitir un evento para notificar que el input ha sido reseteado
        },
        clearFileInput() {
          if (this.$refs.form && (this.$refs.form as any).reset) {
            (this.$refs.form as any).reset();
          }
        },
        async showFile(){
            //@ts-ignore
            if (this.file != null){
                
                //@ts-ignore
                const response = await SolicitudService.descargarFormato(`documentacion/${this.file.data.id}`,{},'GET','Temp');

                let file = new Blob([response.data],{type: response.headers['content-type']});
                const url = window.URL.createObjectURL(file);                

                //@ts-ignore
                this.documento = {
                    //@ts-ignore
                    title: this.label,
                    url: url,
                    mime: response.headers['content-type'] 
                }
                this.dialog = true;
            }
        },
        changeFile() {
            this.show = true;
        },
        dialogDeleteFile() {
            this.dialogDelete = true;
        },
        deleteFile() {
            try {
                SolicitudService.deleteFiles(this.file.data.id, this.Notario.id).then((response:any) => {
                if (response.data.success) {
                    this.dialogDelete = false;
                    this.fileDeleted = true;
                    this.$emit('file-upload-success', {message : response.data.message , typeFile: this.file.data.tipo_archivo});
                }else{
                    this.dialogDelete = false;
                    this.$emit('file-upload-error', {message : response.data.message});
                }
                });
            } catch (error) {
                this.dialogDelete = false;
                console.error("Error al eliminar foto:", error);
            }

        }
    }

});

