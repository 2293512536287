interface _TipoPersona {
  id: number;
  descripcion: string;
}

class TipoPersona implements _TipoPersona {
  id: number = 0;
  descripcion: string = "";
}

export { TipoPersona, _TipoPersona };
