
import Vue from 'vue';
import {CatalogoService} from "@/services/CatalogoService";
import { _TasaImpuestos, TasaImpuestos } from '@/models/TasaImpuestos';
import FormCreate from '@/components/Catalogos/FormUmaCreate.vue'
import EventBus from '@/plugins/event-bus';
import {Vex} from "@/plugins/vex";

export default Vue.extend({
  name: 'Uma',
  components: {
    FormCreate,
  },
  data: () => ({
    headers: [
      { text: 'Valor', align: 'center', sortable: false, value: 'valor' },
      { text: 'Año', align: 'center', sortable: false, value: 'anio' },
      { text: 'Activo', align: 'center', sortable: false, value: 'activo' }
    ],
    formSearch: '',
    element: new TasaImpuestos(),
    service: new CatalogoService('catalogos/tasa-impuestos'),
    dialog: false,
    dialogConfirmation: false,
    isValidating: false,
    list: [] as _TasaImpuestos[],
    isOpen: false,
    form: {
      id: 0,
      statusId: 0,
    },
    footerProps: {
      itemsPerPageText: "Registros por página",
      itemsPerPageAllText: "Todos los registros",
    }
  }),

  computed: {},

  watch: {},
  mounted() {
    this.initialize();
    EventBus.$on("close-request-form", (d: boolean) => {
      this.isValidating = d
    });
  },
  methods: {
    newItem() {
      this.element = Object.assign({});
      this.dialog = true;
    },
    closeDialog(reload: boolean) {
      this.dialog = false;
      if(reload)
        this.cargar();
    },
    async cargar(){
      let dato = {search: this.formSearch}
      let {data} = await this.service.getAll(dato);
      if (data)
        this.list = data;
    },
    async initialize() {
      this.cargar();
    },
    editar(item: any) {
      this.element = Object.assign({}, item);
      this.dialog = true;
    },
    async borrar(item: any) {
      this.dialogConfirmation = true;
      this.element = Object.assign({}, item);
    },
    async eliminar(item: { id: any, year: any}){
      // @ts-ignore
      Vex.dialog.confirm({
        // @ts-ignore
        message: `¿Realmente deseas eliminar la uma del año: ${item.year}?`,
        callback: async (value: any)  => {
          if (value) {
            let {data} = await this.service.delete(item.id);
            if(data.success){
              this.$toast.warning(`Uma eliminada exitosamente`);
              await this.cargar();
            }

          }
        }
      })
    },
    async closeDialogConfirmation(confirmation: boolean, reload: boolean) {
      if (confirmation) {
        this.service.delete(this.element.id).then((data) => {
          if (data.data.success) {
            this.$toast.success(data.data.message);
          }else{
            this.$toast.error(data.data.message);
          }
          this.cargar();
        }).catch((error) => {
          this.$toast.error(error.message);
        });
      }
      this.dialogConfirmation = false;
    },
  },
})
