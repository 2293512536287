import Vue from 'vue';
import Vuex from 'vuex';

import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

const vuexPersistence = new VuexPersistence({
  key: 'apptest-global',
  storage: window.localStorage
})

export default new Vuex.Store({
  plugins: [vuexPersistence.plugin],
  state: {
    sidebar_mini: null,
    id: null,
    usuario: null,
    email:null,
    rfc: null,
    nuevo:null,
    have_generate_password: null,
    perfil_completo: null,
    estatus_id:null,
    role: null,
    session: false,
    token: null,
    expire_at: null,
    modules: [],
    routes: [],
  },
  getters: {
    usuario(state){
      return state.usuario
    },
    email(state){
      return state.email
    },
    rfc(state){
      return state.rfc
    },
    nuevo(state){
      return state.nuevo
    },
    perfil(state){
      return state.have_generate_password
    },
    perfil_completo(state){
      return state.perfil_completo
    },
    status(state){
      return state.estatus_id
    },
    role(state){
      return state.role
    },
    id(state){
      return state.id
    },
    session(state){
      return state.session;
    },
    modules(state){
      return state.modules;
    },
    routes(state){
      return state.routes;
    },
    sidebar(state){
      return state.sidebar_mini;
    }
  },
  mutations: {
    setSidebar(state, value){
      state.sidebar_mini = value;
    },
    setSolicitud(state, value){
      state.estatus_id = value;
    },
    setrfc(state, value){
      state.rfc = value;
    },
    updateUsuario(state, payload){
      state.usuario = payload;
    },
    setToken(state, payload){
      state.id = payload.id;
      state.usuario = payload.name;
      state.email = payload.email;
      state.rfc = payload.rfc;
      state.token = payload.token;
      state.perfil_completo = payload.perfil_completo;
      state.have_generate_password = payload.have_generate_password;
      state.estatus_id = payload.estatus_id;
      state.session = true;
      state.expire_at = payload.expires_at;
      state.modules = payload.modules;
      state.routes = payload.routes;
      state.role = payload.role;
    },
    setPass(state, payload){
      state.id = payload.id;
      state.have_generate_password = payload.have_generate_password;
    },
    logout(state){
      state.id = null;
      state.usuario = null;
      state.token = null;
      state.session = false;
      state.expire_at = null;
      state.modules = [];
      state.routes = [];
      state.role = null;
    }
  },
  actions: {
    setSidebarMini(context, payload){
      context.commit('setSidebar',payload);
    },
    setSolicitud(context, payload){
      context.commit('setSolicitud', payload);
    },
    updateUser(context, payload){
      context.commit('updateUsuario', payload);
    },
    setToken(context, payload){
      context.commit('setToken', payload);
    },
    setPass(context, payload){
      context.commit('setPass', payload);
    },
    logout(context){
      context.commit('logout');
    }
  },
  modules: {
  }
})
