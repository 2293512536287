import {DefaultService} from "@/services/DefaultService";
import API from "@/plugins/axios";

class UsersAuxiliaryService extends DefaultService{

    constructor() {
        super('users-auxiliary');
    }
    toggleStatus(id: any): Promise<any> {
        return API.post(`/${this.url}/${id}/toggleStatus`);
    }

    withoutTablet(): Promise<any>{
        return API.get(`/${this.url}/no-tablet`);
    }

    withoutUnit(): Promise<any>{
        return API.get(`/${this.url}/no-unit`);
    }

    fromUnit(unidad_id: any): Promise<any>{
        return API.get(`/${this.url}/unit/${unidad_id}`);
    }

    addToUnit(user_id: any, unidad_id: any): Promise<any>{
        return API.post(`/${this.url}/${user_id}/unit/${unidad_id}`);
    }

    removeFromUnit(user_id: any): Promise<any>{
        return API.post(`/${this.url}/${user_id}/remove-unit`);
    }

    //Notario
    getById(notario: number | string): Promise<any> {
        return API.get(`/notario/getById/${notario}`);
    }

    getRfc(idN: number | string) : Promise<any>{
        return API.get(`/getRfc/${idN}`);
    }

    updateNotario(data: any ) : Promise<any>{
        return API.post(`/updateNotario`, data);
    }

    changeStatus(usuario: any) : Promise<any>{
        return API.post(`/changeStatus`, usuario);
    }

    getUser(id: number): Promise<any>{
        return API.get(`/getUserNotario/${id}`);
    }
    descargarFormato(url: any, data: any, type: any, name: string){
        return API.request({
            url: url,
            data: data,
            method: type,
            responseType: 'blob'
        });
    }

    deleteFiles(archivo: number,id: any = null ): Promise<any>{
        return API.post(`notarios/${archivo}/${id}/deleteFile`);
    }
    
    getAllData(data: any = null): Promise<any>{
        return API.get(`/getAll`,{params: data});
    }

    getStatus(data: any = null): Promise<any>{
        return API.get(`/getStatus`);
    }

    setValidar(id: number): Promise<any>{
        return API.post(`/setValidar`,id);
    }
    
    setRechazar(data: any): Promise<any>{
        return API.post(`/setRechazar`,data);
    }
}

export default new UsersAuxiliaryService();
