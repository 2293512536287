
import Vue from 'vue'
import LoginService from "@/services/LoginService";
import Notification from '@/components/Common/Notification.vue';
import { mapGetters } from "vuex";

export default Vue.extend({
  name: 'Sidebar',
  components: {
    Notification
  },
  data: () => ({
    drawer: true,
    mini: false,
    dialogPassword: false,
    newPassword: '',
    estatus_id:'',
    role_U: '',
  }),
  computed: {
    ...mapGetters({
      usuario: 'usuario', modules: 'modules', status: 'status', role:'role'
    })
  },
  mounted() {
    this.estatus_id = this.status;
    this.role_U = this.role;
  },
  watch: {
    mini: function (value) {
      this.setMini(value);
    }
  },
  methods: {
    logout() {
      LoginService.logout().then(response => {
        if (response.data.success) {
          this.$store.dispatch('logout');
          Vue.gates.setRoles([]);
          this.$router.push({ name: 'FullLogin' });
        }
      });
    },
    setMini(value: boolean) {
      this.$store.dispatch('setSidebarMini', value);
    },
    cancelChangePassword() {
      this.dialogPassword = false;
      this.newPassword = '';
    },
    submitNewPassword() {
      // Obtener el id del usuario desde el store o de donde sea que esté disponible
      const userId = this.$store.state.id;

      // Llamar al método changePassword del servicio LoginService
      LoginService.changePassword(userId, { password: this.newPassword })
          .then(response => {
            // Manejar la respuesta del servidor si es necesario
            console.log(response.data);
            this.$toast.success('Contraseña cambiada correctamente');
          })
          .catch(error => {
            // Manejar el error si la solicitud falla
            console.error(error);
            this.$toast.error('Error al cambiar la contraseña');
          })
          .finally(() => {
            // Limpiar el campo de nueva contraseña y cerrar el diálogo
            this.cancelChangePassword();
          });
    }
  }
})
