import Json from '@/types/json'
import { Adquiriente } from "./Adquiriente";
import { Enajenante } from "./Enajenante";
import { Escritura } from "./Escritura";
import { Inmueble } from "./Inmueble";
import { Calculo } from "./Calculo";
import {Usuario} from "@/models/Usuario";

interface _FileDocument {
    code: string,
    file: string,
    binary: string,
    data: any  
}

class FileDocument implements _FileDocument {
    code: string
    file: string
    binary: string
    data: any  

    constructor() {
        this.code = '';
        this.file = '';
        this.binary = '';
        this.data = null;
    }
}

interface _Solicitud {
    id: number | null;
    adquirientes: Adquiriente[] | null;
    enajenantes: Enajenante[] | null;
    delete_adquirientes: any;
    delete_enajenantes: any;
    usuario: Usuario | null;
    auxiliar: Usuario | null;
    escritura: Escritura | null;
    inmueble: Inmueble | null;
    calculo: Calculo | null;
    tipo_operacion_id: number | null;
    estatus: string;
    escritura_binary: any | null;
    escritura_publica: FileDocument;
    cedula_binary: any | null;
    cedula_catastral: FileDocument;
    avaluo_binary: any | null;
    avaluo_comercial: FileDocument;
    pago_predial_binary: any | null;
    pago_predial: FileDocument;
    constancia_binary: any | null;
    constancia_no_adeudo: FileDocument;
    certificado_binary: any | null;
    certificado_obras: FileDocument;
    zofemat_binary: any | null;
    zofemat: FileDocument;
    descuento_binary: any | null;
    descuento: FileDocument;
    motivo_rechazo: string;
}

class Solicitud implements _Solicitud{
    id: number = 0;
    adquirientes = [new Adquiriente()];
    enajenantes = [new Enajenante()];
    delete_adquirientes = [] as any;
    delete_enajenantes = [] as any;
    usuario = new Usuario();
    auxiliar = new Usuario();
    escritura = new Escritura();
    inmueble = new Inmueble();
    calculo = new Calculo();
    tipo_operacion_id = null;
    estatus: string = '';
    escritura_binary = '';
    escritura_publica = new FileDocument();
    cedula_binary = '';
    cedula_catastral = new FileDocument();
    avaluo_binary = '';
    avaluo_comercial = new FileDocument();
    pago_predial_binary = '';
    pago_predial = new FileDocument();
    constancia_binary = '';
    constancia_no_adeudo = new FileDocument();
    certificado_binary = '';
    certificado_obras = new FileDocument();
    zofemat_binary = '';
    zofemat = new FileDocument();
    descuento_binary = '';
    descuento = new FileDocument();
    motivo_rechazo: string = '';
    setData(data: Json){

    }
}

export {Solicitud,_Solicitud}