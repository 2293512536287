
import Vue from 'vue'
import RULES from "@/mixins/rules"
import {Adquiriente} from "../../models/Adquiriente";
import {VForm} from "@/types/formvalidate";
import {CatalogoService} from "@/services/CatalogoService";
import {mapGetters} from "vuex";

export default Vue.extend({
  name: 'CardAdquiriente',
  data() {
    return {
      serviceEntidades: new CatalogoService('catalogos/entidad'),
      adquiriente: {} as Adquiriente,
      tipos_persona: [{
        id: "1",
        text: "Persona física"
      },
        {
          id: "2",
          text: "Persona moral"
      }],
      catalogos: {
        entidades: [],
        municipios: [{
          id: 4,
          label: 'OTHON P. BLANCO'
        }],
        entidadDefault: 23,
        municipioDefault: null,
      },
    }
  }
  ,
  props: {
    object: {
      type: Object,
      required: false,
    },
    index: {
      type: Number,
      default: false,
    },
    update: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    form(): VForm {
      return this.$refs.formAdquiriente as VForm;
    },
    ...mapGetters({
      usuario: 'usuario', id: 'id', mini: 'sidebar', role:'role'
    }),
  },
  mounted() {
    if(this.disabled){
      this.adquiriente = this.object;
    }
    this.getEntidades();
  },
  watch: {
    adquiriente: {
      handler(newVal) {
        this.updated();
      },
      deep: true,
    },
  },
  methods: {
    async getEntidades(){
      let {data} = await this.serviceEntidades.getAll();
      this.catalogos.entidades = data;
    },
    validate() {
      return this.form.validate();
    },
    resetValidation() {
      return this.form.resetValidation();
    },
    removeAdquiriente() {
      //call api if item is saved
      this.$emit("delete", this.index);
    },
    updated() {
      this.$emit("update", this.adquiriente, this.index);
    },
  },
  mixins: [RULES],
  directives: {},
})
