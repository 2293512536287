interface _TipoOperacion {
  id: number;
  descripcion: string;
  active: boolean;

}

class TipoOperacion implements _TipoOperacion {
  id: number = 0;
  descripcion: string = "";
  active: boolean = false;
}

export { TipoOperacion, _TipoOperacion };
