import API from '@/plugins/axios'

class DiaService {
    getDias(): Promise<any> {
        return API.get(`/dias`);
    }

    setDia(data: {dia: string, descripcion:string ,estado: string}): Promise<any> {
        return API.post(`/store-dias`, data);
    }
}

export default new DiaService();