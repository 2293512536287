
import Vue from 'vue';
import {CatalogoService} from "@/services/CatalogoService";
import { _Notaria, Notaria } from '@/models/Notaria';
import FormCreate from '@/components/Catalogos/Notarias/FormNotariaCreate.vue'
import EventBus from '@/plugins/event-bus';
import {Vex} from "@/plugins/vex";
import UserService from "@/services/UserService";

export default Vue.extend({
  name: 'Notaria',
  components: {
    FormCreate,
  },
  data: () => ({
    formSearch: '',
    headers: [
      { text: 'Descripción', align: 'start', sortable: false, value: 'descripcion' },
      { text: 'Dirección', align: 'start', sortable: false, value: 'direccion' },
      { text: 'Teléfono', align: 'start', sortable: false, value: 'telefono' },
      { text: 'Localidad', align: 'start', sortable: false, value: 'localidad' },
      { text: 'Entidad', align: 'start', sortable: false, value: 'entidad.descripcion' },
      { text: 'Municipio', align: 'start', sortable: false, value: 'municipio.descripcion' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    element: new Notaria(),
    service: new CatalogoService('catalogos/notaria'),
    dialog: false,
    dialogConfirmation: false,
    isValidating: false,
    list: [] as _Notaria[],
    isOpen: false,
    form: {
      id: 0,
      statusId: 0,
    },
    footerProps: {
      itemsPerPageText: "Registros por página",
      itemsPerPageAllText: "Todos los registros",
    }
  }),

  computed: {},

  watch: {
  },
  mounted() {
    this.initialize();
    EventBus.$on("close-request-form", (d: boolean) => {
      this.isValidating = d
    });
  },
  methods: {
    newItem() {
      this.element = Object.assign({});
      this.dialog = !this.dialog;
    },
    closeDialog(reload: boolean) {
      this.dialog = false;
      if(reload)
        this.cargar();
    },
    async cargar(){
      let dato = {search: this.formSearch}
      let {data} = await this.service.getAll(dato);
      if (data)
        this.list = data;
    },
    async initialize() {
      this.cargar();
    },
    editar(item: any) {
      this.element = Object.assign({}, item);
      console.log(this.element);
      this.dialog = true;
    },
    async borrar(item: any) {
      this.dialogConfirmation = true;
      this.element = Object.assign({}, item);
    },
    async eliminar(item: { id: any, rfc: string , descripcion :string}){
      // @ts-ignore
      Vex.dialog.confirm({
        // @ts-ignore
        message: `¿Realmente deseas eliminar la notaria: ${item.descripcion}?`,
        callback: async (value: any)  => {
          if (value) {
            let {data} = await this.service.delete(item.id);
            if(data.success){
              this.$toast.warning(`Notaria eliminada exitosamente`);
              await this.cargar();
            }else {
              this.$toast.error(data.message)
            }

          }
        }
      })
    },
    async closeDialogConfirmation(confirmation: boolean, reload: boolean) {
      if (confirmation) {
        this.service.delete(this.element.id).then((data) => {
          if (data.data.success) {
            this.$toast.success(data.data.message);
          }else{
            this.$toast.error(data.data.message);
          }
          this.cargar();
        }).catch((error) => {
          this.$toast.error(error.message);
        });
      }
      this.dialogConfirmation = false;
    },
  },
})
