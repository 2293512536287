interface _Notaria {
  id: number;
  descripcion: string;
  direccion: string;
  telefono: string;
  localidad: string;
  entidad_id: number | null;
  municipio_id: number | null;
}

class Notaria implements _Notaria {
  id: number = 0;
  descripcion: string = "";
  direccion: string = "";
  telefono: string = "";
  localidad: string = "";
  entidad_id: number = 0;
  municipio_id: number = 0;

}

export { Notaria, _Notaria };
