
import Vue from 'vue';
import { VForm } from "@/types/formvalidate";
import ViewPDFService from '@/services/ViewPDFService';
import {mapGetters} from "vuex";
import FormSeal from "@/components/Sat/FormSeal.vue"
import FormSealDirectora from "@/components/Sat/FormSealDirectora.vue";
import CustomFileIsabi from "@/components/Common/CustomFileIsabi.vue";
import SimService from "@/services/SimService";
import RULES from "@/mixins/rules";
import {CatalogoService} from "@/services/CatalogoService";
import SolicitudService from '@/services/SolicitudService';
import {Vex} from "@/plugins/vex";
import {Solicitud} from '@/models/Solicitud';


export default Vue.extend({
  name: 'Isabi',
  mixins: [RULES],
  components: {
    FormSeal,
    FormSealDirectora,
    CustomFileIsabi
  },
  data: () => ({
    serviceEstatusSolicitudes: new CatalogoService('catalogos/estatus-solicitud'),
    serviceTipoOperacion: new CatalogoService('catalogos/tipo-operacion'),
    catalogos: {
      estatusSolicitud: [],
      tipoOperacion: []
    },
    datosPago:{
      folio:null,
      fecha_emision:null,
      fecha_expiracion:null,
      estatus_id:null
    },
    fecha_actual: "",
    selected: [],
    expanded: [],
    dialogViewDeclaracion: false,
    dialogViewReciboPago: false,
    showBoton: false,
    dialogSendValidate: false,
    dialogVerPagos: false,
    sendValidateMultiple: false,
    dialogPrintEdoCta: false,
    banderaPDFDocumento: false,
    banderaPDFDocumentoReciboPago: false,
    solicitudIdEdoCta: null,
    solicitud: [],
    solicitudEdosCta: [],
    edoCtaActual: false,
    edoCtaAnterior: false,
    datoSendValidate: {
      id: null,
      folioSendValidate: null,
    },
    documento: null,
    headers: [
      { text: '', align: 'center', sortable: false, value: 'exclusive' },
      { text: 'ESTATUS DE LA SOLICITUD (ISABI)', align: 'start', sortable: false, value: 'estatus' /* value: role === 'notario' ? 'estatus.notario' : 'estatus.municipio' */ },
      { text: 'TIPO DE OPERACIÓN', align: 'start', sortable: false, value: 'tipo_operacion.descripcion' },
      { text: 'NÚM ESCRITURA', align: 'start', sortable: false, value: 'escritura.numero' },
      { text: 'FECHA ESCRITURA', align: 'start', sortable: false, value: 'escritura.fecha_escritura' },
      { text: 'FECHA REGISTRO', align: 'start', sortable: false, value: 'fecha_registro' },
      { text: 'CLAVE CATASTRAL', align: 'start', sortable: false, value: 'inmueble.clave_catastral' },
      { text: 'ORDEN DE PAGO', align: 'start', sortable: false, value: 'solicitud_historial' },
      { text: 'ACCIONES', value: 'actions', sortable: false },
    ],
    pagos:[],
    isValidating: false,
    menu_inicio: false,
    menu_fin: false,
    dialog: false,
    dialogAnuncios: false,
    dialogSeal: false,
    dialogFilter: false,
    anuncios: [],
    list: [],
    itemsPerPage:50,
    form: {
      id: 0,
      statusId: 0,
    },
    formSearch: null,
    validateForm:[],
    datosValidacion:[],
    showValidate: false,
    filterForm:[],
    formFilter: {
      statusSolicitud: null,
      tipoOperacion: null,
      claveCatrastal: null,
    },
    exportForm:{
      fecha_inicio: null,
      fecha_fin: null,
    },
    dialogR: false,
    formRechazo: {
        id: '',
        motivo_rechazo: '',
    },
    dialog_descuento: false,
    tipo_descuento: [
      {
        id: 0,
        text: "Cantidad",
      },
      {
        id: 1,
        text: "Porcentaje",
      }
    ],
    aplica_descuento: [
      {
        id: 0,
        text: "Impuesto neto",
      },
      {
        id: 1,
        text: "Impuesto actualizado",
      },
      {
        id: 2,
        text: "Recargos",
      },
      {
        id: 3,
        text: "Total a pagar",
      }
    ],
    tipo_descuento_valor: null,
    aplica_descuento_valor: null,
    valor_descuento: '',
    formSolicitud: new Solicitud(),
    disabledDescuentos: false
  }), computed: {
    formRe(): VForm {
      return this.$refs.formRechazo as VForm;
    },
    formDes(): VForm {
      return this.$refs.formDescuento as VForm;
    },
    ...mapGetters({
        id:'id', usuario: 'usuario', modules: 'modules', email: 'email', status: 'status', role:'role'
    })
  },

  watch: {
    edoCtaActual: {
      handler: function (valor) {
        if(valor){
          this.edoCtaAnterior = false
        }
      }
    },
    edoCtaAnterior: {
      handler: function (valor) {
        if(valor){
          this.edoCtaActual = false
        }
      }
    }
  },
  created() {
    // @ts-ignore
   this.expanded = this.list.map(item => item.id);
  },
  mounted() {
    this.reflejarPagos();
    this.initialize();
    this.getAllData();
  },
  methods: {
    async reflejarPagos(){
      await SolicitudService.reflejarPagadosPendientes().then(
          response => {
          // console.log(response);
          }
      );
    },
    // @ts-ignore
    getFolioSim(item){
      return item.solicitud_historial[item.solicitud_historial.length - 1].solicitud_edo_cta[item.solicitud_historial[item.solicitud_historial.length - 1].solicitud_edo_cta.length - 1].folio_sim
    },
    // @ts-ignore
    abrirVentanaPagoLinea(item) {
      let folioPago = (item.solicitud_historial[item.solicitud_historial.length - 1].solicitud_edo_cta[item.solicitud_historial[item.solicitud_historial.length - 1].solicitud_edo_cta.length - 1].folio_sim);
      const url = 'https://tics.opb.gob.mx/motor/pagos_linea.php';
      const ancho = 1200;
      const alto = 800;

      // Centrar la ventana flotante
      const izquierda = (window.screen.width / 2) - (ancho / 2);
      const arriba = (window.screen.height / 2) - (alto / 2);

      // Crear un formulario y enviarlo en una nueva ventana
      const formulario = document.createElement('form');
      formulario.method = 'post';
      formulario.action = url;
      formulario.target = 'ventanaFlotante';
      formulario.style.display = 'none'; // Ocultar el formulario

      // Crear los campos del formulario
      const campos = [
        { name: 'idServicio', value: '1757' },
        { name: 'claveEstablecimiento', value: folioPago },
        { name: 'estatusEstadoCuenta', value: 'A' },
        { name: 'folioEstadoCuenta', value: 1 }
      ];

      campos.forEach(campo => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = campo.name;
        input.value = campo.value;
        formulario.appendChild(input);
      });

      // Añadir el formulario al documento y enviarlo
      document.body.appendChild(formulario);

      // Abrir la ventana emergente
      // @ts-ignore
      const ventanaEmergente = window.open('', 'ventanaFlotante', `width=${ancho},height=${alto},top=${arriba},left=${izquierda},resizable=yes,scrollbars=yes`);

      // Enviar el formulario
      formulario.submit();
      // @ts-ignore
      // Verificar el estado de la ventana emergente
      const verificarVentana = setInterval(() => {
        // @ts-ignore
        if (ventanaEmergente.closed) {
          clearInterval(verificarVentana); // Detener el intervalo cuando la ventana se cierra
          this.validaPago(folioPago);
        }
      }, 500); // Verificar cada 500ms si la ventana está cerrada

      // Limpiar el formulario
      document.body.removeChild(formulario);

    },
    handleFileUploadSuccess(data: any) {
      // type FormUserKey = keyof typeof this.formUser;
      const typeFile = data.typeFile;
      const message = data.message;
      // @ts-ignore
      this.formSolicitud[typeFile].data = null;
      this.$toast.success(message);
    },
    handleFileUploadError (data: any){
      const message = data.message;
      this.$toast.error(message);
    },
    async verPagos(){
        await SolicitudService.getAllPagos().then(
          response => {
            if(response.data.success){
              this.pagos = response.data.data;
              this.pagos = this.pagos.filter(dt => dt != false)
              console.log(this.pagos);
              this.pagos.length == 0 ? this.dialogVerPagos = false : this.dialogVerPagos = true;
              this.initialize();
              this.$toast.success(response.data.message);
            }else{
              this.$toast.error("Pagos no recuperados");
            }
          }
        );
    },
    // @ts-ignore
    formatClaveCatastral(clave) {
      if (!clave) return ''
      // Asegúrate de que la clave sea una cadena
      clave = clave.toString()
      // Formatear la clave catastral según el patrón ####-###-####-######
      return `${clave.slice(0, 4)}-${clave.slice(4, 7)}-${clave.slice(7, 11)}-${clave.slice(11)}`
    },
    // @ts-ignore
    formatPrecio(precio) {
      return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(precio)
    },
    // @ts-ignore
    getColorByStatusNotario(status: string): string {
      if( this.role === 'notario' || this.role === 'auxiliar' ) {
        switch (status) {
          case 'PENDIENTE DE FIRMA':
            return '#F9A825';
          case 'FIRMADO, LISTO PARA ENVÍO':
            return '#42ade5';
          case 'EN VALIDACIÓN POR EL ÁREA':
            return '#439343';
          case 'RECHAZADO POR EL ÁREA':
            return '#E53935';
          case 'VALIDADO Y PENDIENTE DE FIRMA POR EL ÁREA':
            return '#4DB6AC';
          case 'FIRMADO POR EL ÁREA':
            return '#396926';
          case 'ORDEN DE PAGO GENERADO':
            return ' #7133ff';
          default:
            return 'grey';
        }
      }
      if( this.role === 'operativoMunicipio' ) {
        switch (status) {
          case 'PENDIENTE DE VALIDAR':
            return '#F9A825';
          case 'RECHAZADO POR EL ÁREA':
            return '#E53935';
          case 'PEND. FIRMA DIRECTORA':
            return '#2e93c4';
          case 'FIRMADO POR LA DIRECTORA':
            return '#43A047';
          case 'FINALIZADO':
            return '#33691E';
          case 'ORDEN DE PAGO GENERADO':
            return ' #7133ff';
          default:
            return 'grey';
        }
      }
    },
    selecteds(event: any, valor: never) {
      const datos: never[] = this.selected
      if (event){
        this.selected.push(valor)
      }else{
        // @ts-ignore
        this.selected = datos.filter(dt => valor.id !== dt.id)
      }
    },
    filterEstatusSolicitudes(event: any, valor: never){
      let datos: never[] = this.filterForm
      if(event) {
        this.filterForm.push(valor)
      }else{
        this.filterForm = datos.filter(dt => dt !== valor)
      }
    },
    enviarMultiSolicitudes(event: any, valor: never){
      let datos: never[] = this.validateForm
      if(event) {
        this.validateForm.push(valor)
      }else{
        this.validateForm = datos.filter(dt => dt !== valor)
      }
      //@ts-ignore
      this.datoSendValidate.id = '0';
      //@ts-ignore
      this.datoSendValidate.folioSendValidate = '0';
    },
    async exportIsabi() {

      try {
        let payload = { role: this.role}
        const response = await SolicitudService.ExportarIsabi(payload)
            .then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const a = document.createElement('a');
              a.style.display = 'none';
              a.href = url;
              a.download = 'export.xls';
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
            });
      } catch (e) {
        console.error('Error descargando el archivo:', e);
      }


    },
    //@ts-ignore
    async validaPago(folio) {
      try {
        let payload = { folio: folio}
        const response = await SolicitudService.ValidarPagoOPB(payload)
            .then((response) => {
              location.reload();
            });
      } catch (e) {
      }
    },
    async getAllData() {
      try {
        const [estatusSolicitudRes] = await Promise.all([
          this.serviceEstatusSolicitudes.getAll()
        ]);
        const [tipoOperacionRes] = await Promise.all([
        //SimService.getTipoOperaciones()
        this.serviceTipoOperacion.getAll()
        ]);
        if(this.role == "operativoMunicipio"){
          //@ts-ignore
          this.catalogos.estatusSolicitud = estatusSolicitudRes.data.filter(dt => dt.id > 2);
        } else if (this.role == "directoraMunicipio") {
        //@ts-ignore
        this.catalogos.estatusSolicitud = estatusSolicitudRes.data.filter(dt => dt.id >= 5);
      } else{
          this.catalogos.estatusSolicitud = estatusSolicitudRes.data;
        }

        this.catalogos.tipoOperacion = tipoOperacionRes.data;
        //this.catalogos.tipoOperacion = tipoOperacionRes.data.data;
      } catch (error) {
        console.error("Error al obtener datos:", error);
        // Maneja el error apropiadamente según tu lógica de la aplicación
      }
    },
    async updateEstatus() {
      if(this.validateForm.length != 0){
        this.validateForm.forEach(item => {
          //@ts-ignore
          this.datosValidacion.push(item.id)
        });
      }
      let dato = {estatus_id: 3 , multipleSolicitud: this.datosValidacion}
      const data = await SolicitudService.updateStatus(this.datoSendValidate.id, dato);
      if (data.data.success) { //data['success']
        this.$toast.success(data.data.message);
      }else{
        this.$toast.error(data.data.message);
      }
      this.initialize();
      this.validateForm = [];
      setTimeout(() => {
        this.dialogSendValidate = false
      },500)

    },
    async updateAprobar() {
      // solicitudIdEdoCta
      // @ts-ignore
      Vex.dialog.confirm({
        // @ts-ignore
        message: `¿Está seguro de que desea aprobar la orden de pago generada? Al aprobar, se asignará un folio de pago proporcionado por cajas`,
        buttons: [
          Vex.dialog.buttons.YES = {
            text: 'Aprobar',
            className: 'vex-dialog-button-primary',
            click: function() {
              this.value = true;
              this.close();
            }
          },
          Vex.dialog.buttons.NO = {
            text: 'Cancelar',
            className: 'vex-dialog-button-secondary',
            click: function() {
              this.value = false;
              this.close();
            }
          }
        ],
        //Aprobar estado de cuenta
        callback: async (value: any)  => {
          if (value) {
            let dato = {solicitud: this.solicitud}
            let {data} = await SolicitudService.updateAprobar(dato);
            if(data.success){
              this.$toast.success(`El orden de pago ha sido aprobado exitosamente`);
              await this.initialize();
              this.dialogPrintEdoCta = false;
              this.dialogViewDeclaracion = false;
            }else {
              this.dialogPrintEdoCta = false;
              this.dialogViewDeclaracion = false;
              this.$toast.error(data.message)
            }
          }
        }
      })
    },
    async initialize() {
      // console.log({statusSolicitud: this.formFilter, search: this.formSearch})
      let dato = {
        id:this.id,
        role:this.role,
        statusSolicitud: this.formFilter.statusSolicitud,
        statusSolicitudMulti: this.filterForm,
        tipoOperacion: this.formFilter.tipoOperacion,
        claveCatrastal: this.formFilter.claveCatrastal,
        search: this.formSearch
      }
      let date = new Date()
      let day = `${(date.getDate())}`.padStart(2,'0');
      let month = `${(date.getMonth()+1)}`.padStart(2,'0');
      let year = date.getFullYear();
      this.fecha_actual = `${year}-${month}-${day}`;
      await SolicitudService.getAll(dato).then(
        (data): any => {
          this.list = data.data;
          const datos = data.data

          if(this.role === 'operativoMunicipio'){
            this.list = datos.filter((dt: { estatus_id: number; }) => dt.estatus_id >= 3)
          } else if (this.role == "directoraMunicipio") {
            //@ts-ignore
            this.list = datos.filter((dt: { estatus_id: number; }) => dt.estatus_id >= 5)          }
        });
        this.list.forEach(item => {
          //@ts-ignore
          console.log(item.solicitud_historial[item.solicitud_historial.length - 1].solicitud_edo_cta[0]);
        })
        //@ts-ignore
        console.log(this.list.find(item => item.estatus_id == 3) == undefined ? this.showValidate = true : this.showValidate = false);
    },
    //@ts-ignore
    async descargarReciboPagoOnline(item,folio) {
      await ViewPDFService.viewPDF( '/reportes/genera-recibo-pago', {solicitud:item,folio:folio}, 'POST', 'TEMP')
          .then((response) => {
            let file = new Blob([response.data],{type: response.headers['content-type']});
            const url = window.URL.createObjectURL(file);
            const tipoArchivo = 'test'
            //@ts-ignore
            this.documento = {
              title: tipoArchivo,
              url: url,
              mime: response.headers['content-type']
            }
            this.banderaPDFDocumentoReciboPago = true;
            this.dialogViewReciboPago = true;
            this.showBoton = false;
          });
    },
    // @ts-ignore
    async descargarPdf(item) {
      await ViewPDFService.viewPDF( '/reportes/genera-declaracion', {solicitud:item}, 'POST', 'TEMP')
      .then((response) => {
        let file = new Blob([response.data],{type: response.headers['content-type']});
        const url = window.URL.createObjectURL(file);
        const tipoArchivo = 'test'
        //@ts-ignore
        this.documento = {
          title: tipoArchivo,
          url: url,
          mime: response.headers['content-type']
        }
        this.banderaPDFDocumento = true;
        this.dialogViewDeclaracion = true;
        this.showBoton = false;
      });
    },
    async SolicitarEdoCta(item:any){
      await SolicitudService.SolicitarEdoCta(item).then(
        (response) => {
          if(response.data.success){
            this.$toast.success(response.data.message);
          }else{
            this.$toast.error(response.data.message)
          }
        }
      )
    },
    async descargarPdfEstadoCuenta(item:any) {
      //const reporte_actual = this.edoCtaActual ? this.edoCtaActual : this.edoCtaAnterior ? this.edoCtaAnterior : null;
      if(!this.edoCtaActual && !this.edoCtaAnterior && this.role == 'operativoMunicipio' ){
        this.$toast.error('Se requiere seleccionar una opcion');
      }else{
        await ViewPDFService.viewPDF( '/reportes/estado-cuenta', {solicitud:item, reporte_actual:this.edoCtaActual, reporte_anterior: this.edoCtaAnterior}, 'POST', 'TEMP')
            .then((response) => {
              let file = new Blob([response.data],{type: response.headers['content-type']});
              const url = window.URL.createObjectURL(file);
              const tipoArchivo = 'test'
              //@ts-ignore
              this.documento = {
                title: tipoArchivo,
                url: url,
                mime: response.headers['content-type']
              }
              this.banderaPDFDocumento = false;
              this.dialogViewDeclaracion = true;
              this.showBoton = true;
            });
        this.initialize();
        this.dialogPrintEdoCta = false;
        // this.edoCtaActual = false;
        // this.edoCtaAnterior = false;
      }
    },
    verDetalle(item: any){
      this.$router.push({name: 'isabi', params: { isabiId: item }}).catch(() => {
      });
    },
    // @ts-ignore
    async Validar(item){
        await SolicitudService.ValidarSoli({solicitud:item}).then(
            (response) => {
                if(response.data.success){
                    this.$toast.success(response.data.message);
                }else{
                    this.$toast.error(response.data.message);
                }
              setTimeout(() => {
                location.reload();
              }, 2000)
            }
        );
    },
    // @ts-ignore
    async RechazarDialog(item){
        this.formRechazo.id = item;
        this.dialogR = true;
    },
    // @ts-ignore
    async RechazarEnvio(){
        if(this.formRe.validate()){
          await SolicitudService.RechazarSoli(this.formRechazo).then(
                (response) => {
                    if(response.data.success){
                        this.dialogR = false;
                        this.$toast.success(response.data.message);
                    }else{
                        this.$toast.error(response.data.message);
                    }
                  setTimeout(() => {
                    location.reload();
                  }, 2000)
                }

            );
        }
    },
    descuentoModal(item: any){
      this.formSolicitud.id       = item.id;    
      this.formSolicitud.calculo  = item.calculo;
      if(this.formSolicitud.calculo.descuento_id != null){
        this.disabledDescuentos     = true;
        // @ts-ignore
        this.tipo_descuento_valor   = parseInt(item.calculo.descuentos.tipo_descuento);
        // @ts-ignore
        this.aplica_descuento_valor = parseInt(item.calculo.descuentos.aplica_a);
        this.valor_descuento        = item.calculo.descuentos.descuento;
        //Archivos
        let archivos = item.archivos;
        let _this    = this;
        archivos.forEach(function callback(value: any) {
          if(value.tipo_archivo == 'descuento'){
            _this.formSolicitud.descuento.data = value;
          }
        });
      }
      else{
        //Reiniciar datos
        this.disabledDescuentos     = false;
        this.tipo_descuento_valor   = null;
        this.aplica_descuento_valor = null;
        this.valor_descuento        = '';
        //this.formSolicitud.descuento_binary = '';
      }
      //Lanzar modal
      this.dialog_descuento = true;
    },
    async aplicaDescuento(){
      if(this.formDes.validate()){
        // @ts-ignore
        if(parseInt(this.tipo_descuento_valor) === 1){
          if (parseFloat(this.valor_descuento) >= 1 && parseFloat(this.valor_descuento) <= 100) {
            let datos = {
              tipo_descuento: this.tipo_descuento_valor,
              aplica_a: this.aplica_descuento_valor,
              descuento: this.valor_descuento,
              solicitud: this.formSolicitud
            }
            const data = await SolicitudService.aplicarDescuento(datos);
            if (data.data.success) {
              this.$toast.success(data.data.message);
              this.dialog_descuento = false;
              setTimeout(() => {
                location.reload();
              }, 2000);
            }
            else{
              this.$toast.error(data.data.message);
            }
          }
          else{
            this.$toast.warning("El porcentaje debe ser un valor entre 1 y 100");
          }
        }
        else{
          if(parseFloat(this.valor_descuento) >= 1){
            let datos = {
              tipo_descuento: this.tipo_descuento_valor,
              aplica_a: this.aplica_descuento_valor,
              descuento: this.valor_descuento,
              solicitud: this.formSolicitud
            }
            const data = await SolicitudService.aplicarDescuento(datos);
            if (data.data.success) {
              this.$toast.success(data.data.message);
              this.dialog_descuento = false;
              setTimeout(() => {
                location.reload();
              }, 2000);
            }
            else{
              this.$toast.error(data.data.message);
            }
          }
          else{
            this.$toast.warning("El valor del descuento debe ser mayor 0");
          }
        }
      }
      else{
        this.$toast.warning("Todos los campos son requeridos"); 
      }
    }
  },
})
