
import Vue from 'vue'
import RULES from "@/mixins/rules"
//import { Enajenante } from "@/models/Enajenante";
import {Enajenante} from "../../models/Enajenante";
import {VForm} from "@/types/formvalidate";
import {CatalogoService} from "@/services/CatalogoService";

export default Vue.extend({
  name: 'CardEnajenante',
  data() {
    return {
      serviceEntidades: new CatalogoService('catalogos/entidad'),
      enajenante: {} as Enajenante,
      tipos_persona: [{
        id: "1",
        text: "Persona física"
      },
        {
          id: "2",
          text: "Persona moral"
      }],
      catalogos: {
        entidades: [],
        municipios: [{
          id: 4,
          label: 'OTHON P. BLANCO'
        }],
        entidadDefault: 23,
        municipioDefault: null,
      },
    }
  }
  ,
  props: {
    object: {
      type: Object,
      required: false,
    },
    index: {
      type: Number,
      default: false,
    },
    update: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // contribuyente: {
    //     type: Number,
    //     default: 0
    // },
  },
  computed: {
    form(): VForm {
      return this.$refs.formEnajenante as VForm;
    }
  },
  mounted() {
    if(this.disabled){
      this.enajenante = this.object;
    }
    this.getEntidades();
  },
  watch: {
    enajenante: {
      handler(newVal) {
        this.updated();
      },
      deep: true,
    },
  },
  methods: {
    async getEntidades(){
      let {data} = await this.serviceEntidades.getAll();
      this.catalogos.entidades = data;
    },
    validate() {
      return this.form.validate();
    },
    resetValidation() {
      return this.form.resetValidation();
    },
    removeEnajenante() {
      //call api if item is saved
      this.$emit("delete", this.index);
    },
    updated() {
      this.$emit("update", this.enajenante, this.index);
    },
  },
  mixins: [RULES],
  directives: {},
})
