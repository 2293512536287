
import Vue from "vue";
import { mapGetters } from "vuex";
import RULES from "@/mixins/rules";
import UserService from "@/services/UserService";
import { VForm } from "@/types/formvalidate";
import CustomFile from "@/components/Common/CustomFile.vue";
import {Vex} from "@/plugins/vex";
import {CatalogoService} from "@/services/CatalogoService";
import { Usuario } from "@/models/Usuario";
//import { jsPDF } from "jspdf";
// @ts-ignore
export default Vue.extend({
    name: "FormUpdate",
    components: {
        CustomFile,
    },
    mixins: [RULES],
    data() {
    return {
        serviceNotaria: new CatalogoService('notarias'),
        terminated: false,
        notarias: [],
        formDefault: new Usuario(),
        formUser : new Usuario(),
        noShow: true
    };
    },
    computed: {
        Usuario() {
            return Usuario;
        },
        form(): VForm {
            return this.$refs.form as VForm;
        },
        ...mapGetters({
            usuario: 'usuario', modules: 'modules' , email: 'email' , perfil: 'perfil', id:'id', status: 'status', rfc: 'rfc', mini: "sidebar", role:'role',
        })
    },
    // @ts-ignore
    mounted() {
        if(this.role != "notario"){
            this.$router.push({name: 'Full'});
        }
        if(this.role === "notario") {
            this.formUser.rfc = this.rfc;
            this.formUser.email = this.email;
            this.formUser.status = this.status;
            this.formUser.id = this.id;
            this.getNotarias();
            this.getData();
        }
    },
    methods: {
        handleFileUploadSuccess(data: any) {
            // type FormUserKey = keyof typeof this.formUser;
            const typeFile = data.typeFile;
            const message = data.message;
            //@ts-ignore
            this.formUser[typeFile] = null;
            this.$toast.success(message);
        },
        handleFileUploadError (data: any){
            const message = data.message;
            this.$toast.error(message);
        },
        async getData(){
            await UserService.getUser(this.id).then(
                (response) => {
                    if(response.data.success){
                        this.formDefault = response.data.data.data;
                        // @ts-ignore
                         this.formUser.grado_academico = this.formDefault.grado_academico;
                        // @ts-ignore
                        this.formUser.nombre = this.formDefault.nombre;
                        // @ts-ignore
                        this.formUser.primer_apellido = this.formDefault.primer_apellido;
                        // @ts-ignore
                        this.formUser.segundo_apellido = this.formDefault.segundo_apellido;
                        // @ts-ignore
                        this.formUser.curp = this.formDefault.curp;
                        // @ts-ignore
                        this.formUser.telefono = this.formDefault.telefono;
                        // @ts-ignore
                        this.formUser.numero_notaria = this.formDefault.notaria_id;
                        // @ts-ignore
                        this.formUser.motivo_rechazo = this.formDefault.motivo_rechazo;
                        // @ts-ignore
                        this.formUser.patente = this.formDefault.documento;
                        let notaria = response.data.data.data.notaria;
                        console.log(notaria);
                        //@ts-ignore
                        this.formUser.direccion_notaria = notaria?.direccion;
                        //@ts-ignore
                        this.formUser.localidad_notaria = notaria?.localidad;
                        //@ts-ignore
                        if(notaria?.municipio_id != null){
                            //@ts-ignore
                            this.formUser.municipio_notaria = notaria?.municipio?.descripcion;
                            this.noShow = true;
                        }else{
                            this.noShow = false;
                        }
                        //@ts-ignore
                        this.formUser.entidad_notaria = notaria?.entidad?.descripcion;
                        if(this.formUser.status != 1 && this.formUser.status != 4){
                            this.terminated = true;
                        }
                    }
                }
            );
        },
        async getNotarias(){
            let {data} = await this.serviceNotaria.getAll();
            this.notarias = data;
        },
        // @ts-ignore
        async update(){
          console.log(this.form.validate());
            if(this.form.validate()){
                this.formUser.id = this.id;
                await UserService.updateNotario(this.formUser).then(
                    (response) => {
                        if (response.data.success) {
                            this.$store.dispatch("setSolicitud", response.data.data.estatus_id);
                            this.$toast.success(response.data.message);
                        } else {
                            this.$toast.error(response.data.message);
                        }
                    }
                );
                await this.$store.dispatch("updateUser",
                this.formUser.nombre + ' ' +
                this.formUser.primer_apellido + ' ' +
                this.formUser.segundo_apellido );
                location.reload();
            }
        },
        async enviarRevision(){
            await UserService.changeStatus({ usuario: this.id }).then(
                (response) => {
                    if(response.data.success){
                        this.terminated = true;
                        this.$store.dispatch("setSolicitud", response.data.data.estatus_id);
                        this.$toast.success('Su solicitud ha sido enviada exitosamente');
                    } else {
                        this.$toast.error('Ocurrio un error al enviar su solicitud, intente de nuevo');
                    }

                }
            );
          setTimeout(() => {
            location.reload();
          }, 3000);
        },

        async traerDireccion(){
            let id = this.formUser.numero_notaria;
            let dato = { id: id };
            console.log(id);
            await UserService.validarUserNotaria(dato).then(
                (response) => {
                    if(response.data.success){
                        this.$toast.success(response.data.message);
                        this.notarias.forEach(
                            (item) => {
                                //@ts-ignore
                                if(item.id == id){
                                    //@ts-ignore
                                    this.formUser.direccion_notaria = item.direccion;
                                    //@ts-ignore
                                    this.formUser.localidad_notaria = item.localidad;
                                    //@ts-ignore
                                    if(item.municipio != null){
                                        //@ts-ignore
                                        this.formUser.municipio_notaria = item.municipio.descripcion;
                                        this.noShow = true;
                                    }else{
                                        this.noShow = false;
                                    }
                                    //@ts-ignore
                                    this.formUser.entidad_notaria = item.entidad.descripcion;
                                }
                            }
                        );
                    } else {
                        this.formUser.numero_notaria = 0;
                        this.formUser.direccion_notaria = '';
                        this.formUser.localidad_notaria = '';
                        this.formUser.municipio_notaria = '';
                        this.formUser.entidad_notaria = '';
                        this.$toast.error(response.data.message);
                    }
                }
            );
        }
    },
});
