
    import Vue from 'vue'
    import EventBus from '@/plugins/event-bus'
    import AvisoPrivacidad from '@/components/Common/Footer.vue';

    export default Vue.extend({
        name: 'FullLogin',
        components: {
          AvisoPrivacidad
        },
        data: () => ({
            dialog: false,
            overlay: false,
        }),
        created(){
            EventBus.$on('before-request', (record: any) => {
                this.overlay = true;
            });
            EventBus.$on('after-response', (record: any) => {
                this.overlay = false;
            });
            EventBus.$on('response-error', (record: any) => {
                this.overlay = false;
            });
        },
        computed: {
          isSignUp() {
            if( this.$route.name === 'SignUp' ){
              return false
            }else{
              return true
            }
          }
        }
    })
