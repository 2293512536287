import Json from '@/types/json'
interface _Adquiriente {
    id: number | null;
    tipo_persona_id: number | null;
    nombre: string;
    domicilio: string;
    telefono: string;
    rfc: string;
    porcentaje: number | null;
    poblacion: string;
    entidad_id: number | null;
    // id_estado_adquiriente: number | null;
    // id_municipio_adquiriente: number | null;
    // id_localidad_adquiriente: number | null;
}

class Adquiriente implements _Adquiriente{
    id: number = 0;
    tipo_persona_id = 0;
    nombre: string = '';
    domicilio: string = '';
    telefono: string = '';
    rfc: string = '';
    porcentaje = null;
    poblacion: string = '';
    entidad_id = 0;
    setData(data: Json){

    }
}

export {Adquiriente,_Adquiriente}