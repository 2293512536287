import Json from '@/types/json'
interface _Escritura {
    id: number | null;
    numero: number | null;
    fecha_escritura: Date | null;
    fecha_limite: Date | null;
    fecha_autorizacion: Date | null; 
    entidad_id: number | null;
    poblacion: string;
}

class Escritura implements _Escritura{
    id: number         = 0;
    numero             = null;
    fecha_escritura    = null;
    fecha_limite       = null;
    fecha_autorizacion = null;
    entidad_id = 0;
    poblacion: string = '';
    setData(data: Json){
    }
}

export {Escritura,_Escritura}