interface _Uma {
  id: number;
  year: number;
  valor_diario: number;
  valor_mensual: number;
  valor_anual: number;
}

class Uma implements _Uma {
  id: number = 0;
  year: number = 0;
  valor_diario: number = 0;
  valor_mensual: number = 0;
  valor_anual: number = 0;
}

export { Uma, _Uma };
