import Json from '@/types/json'
interface _Calculo {
    id: number | null;
    aplica_subsidio: boolean | null;
    tipo_subsidio: number | null;
    descuento_subsidio: number | null;
    base_gravable_completo: number | null;
    base_gravable: number | null;
    impuesto: number | null;
    impuesto_neto: number | null;
    impuesto_actualizado_id: number | null;
    impuesto_actualizado: number | null;
    inpc_actual_id: number | null;
    inpc_limite_id: number | null;
    factor: number | null;
    cadena_inpc: string | null;
    recargo_id: number | null;
    recargo: number | null;
    tasa_recargo_id: number | null;
    meses: number | null;
    tasa_moratoria: number | null;
    cadena_recargo: string | null;
    descuento_id: number | null;
    tipo_descuento: string | null;
    aplica_a: string | null;
    descuento: number | null;
    total_a_pagar: number | null;
}

class Calculo implements _Calculo{
    id: number = 0;
    aplica_subsidio = false;
    tipo_subsidio = 0;
    descuento_subsidio = 0;
    base_gravable_completo = 0;
    base_gravable = 0;
    impuesto = 0;
    impuesto_neto = 0;
    impuesto_actualizado_id = null;
    impuesto_actualizado = 0;
    inpc_actual_id = null;
    inpc_limite_id = null;
    factor = null;
    cadena_inpc = null;
    recargo_id = null;
    recargo = 0;
    tasa_recargo_id = null;
    meses = null;
    tasa_moratoria = null;
    cadena_recargo = null;
    descuento_id = null;
    tipo_descuento = '';
    aplica_a = '';
    descuento = null;
    total_a_pagar = 0;
    setData(data: Json){
    }
}

export {Calculo,_Calculo}