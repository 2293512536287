<template>
    <v-container>
        <v-row>
            <v-col>

            </v-col>
        </v-row>
        <v-row>
          <v-col cols="3"   v-role="'operativoMunicipio'">
            <v-card shaped raised color="">
              <v-list-item three-line :to="{ name: 'notarios'}">
                <v-list-item-avatar
                    tile
                    size="80"
                >
                  <v-icon size="80">mdi-account-multiple</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <div class="title">PADRÓN DE NOTARIOS</div>
                  <v-list-item-title class="headline mb-1" >
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-right"></v-list-item-subtitle>
                </v-list-item-content>


              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="3" v-role="'operativoMunicipio'">
            <v-card shaped raised color="">
              <v-list-item three-line :to="{ name: 'notarias'}">
                <v-list-item-avatar
                    tile
                    size="80"
                >
                  <v-icon size="80">mdi-account-multiple</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <div class="title">PADRÓN DE NOTARIAS</div>
                  <v-list-item-title class="headline mb-1" >
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-right"></v-list-item-subtitle>
                </v-list-item-content>


              </v-list-item>
            </v-card>
          </v-col>

          <v-col cols="3" v-role="'operativoMunicipio'">
            <v-card shaped raised color="">
              <v-list-item three-line :to="{ name: 'tasarecargos'}">
                <v-list-item-avatar
                    tile
                    size="80"
                >
                  <v-icon size="80">mdi mdi-currency-usd</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <div class="title">Tasa de recargos</div>
                  <v-list-item-title class="headline mb-1" >
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-left">

                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="3" v-role="'operativoMunicipio'">
            <v-card shaped raised color="">
              <v-list-item three-line :to="{ name: 'inpc'}">
                <v-list-item-avatar
                    tile
                    size="80"
                >
                  <v-icon size="80">mdi-archive</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <div class="title">INPC</div>
                  <v-list-item-title class="headline mb-1" >
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-left">
                    Índice nacional de precios al consumidor
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <IsabisDashboard></IsabisDashboard>
        </v-row>
        <Calendario></Calendario>
    </v-container>
</template>


<script>
import Vue from 'vue'

import LineChart from '@/components/charts/LineChart.js'
import PieChart from "@/components/charts/PieChart";
import AreaChart from "@/components/charts/AreaChart";
import StackedChart from "@/components/charts/StackedChart";
import BarChart from "@/components/charts/BarChart";
import Calendario from "@/components/Calendario/index.vue";
import IsabisDashboard from '@/components/Dashboard/isabisDashboard.vue';
import {mapGetters} from "vuex";

export default {
    name: 'Dashboard',
    mixins: [],
    components: {
      LineChart, PieChart, AreaChart, StackedChart, BarChart, Calendario, IsabisDashboard
    },
    directives: {},
    data: () => ({
      
    }),
    mounted() {
      
    },
    computed: {
        ...mapGetters({
        usuario: 'usuario', modules: 'modules' , email: 'email' , perfil: 'perfil'
      })
    },
    watch: {},
    methods: {
    }
}
</script>
<style>
.small {
  position: relative;
  max-height: 100px;
}
</style>
