
export default {
  name: "Footer",
  methods: {
    verAvisoPrivacidad() {
      const rutaPDF = process.env.BASE_URL + "pdf/aviso_privacidad.pdf";

      window.open(rutaPDF, "_blank");
      
    },
  },
};
