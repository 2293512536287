interface _Subsidio {
  id: number;
  valor: number;
  dias_vivienda_interes_social: number;
  dias_vivienda_popular: number;
  anio: number;
  activo: boolean;
}

class Subsidio implements _Subsidio {
  id: number = 0;
  valor: number = 0;
  dias_vivienda_interes_social: number = 0;
  dias_vivienda_popular: number = 0;
  anio: number = 0;
  activo: boolean = false;
}

export { Subsidio, _Subsidio };
